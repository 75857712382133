import React from "react";
import Img from "../images/whatwedo.jpg";
import FeatureImg from "./FeatureImg";
import FeatureHeadline from "./FeatureHeadline";
import SliderContainer from "./SliderContainer";
import { StaticQuery, graphql } from "gatsby";

// we partner with brands to build new platforms which further thier business.
const OurWorks = () => {
  const config = {
    titleConfig: {
      metaTitle:"works",
      title: "Who have we helped?",
      subTitle: `Our works have increased sales, improved page visits, and engagement for previous partners.`
    },
    content: "slider",
    image: Img,
    tag: "works",
    button: {
      type: "ghost",
      text: "Contact Us",
      to: "#contact"
    },
    slider: {
      title: "Featured Clients",
    }
  };
  return (
    <StaticQuery
    query={graphql`
    query {
      works: file(relativePath: { eq: "main/works_main2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    `}
      render={data => (
        <section id="works" className="home__section section--works">
          <div className={`feature`}>
            <div className="feature__header">
              <FeatureHeadline config={config} />
              <FeatureImg image={data.works} altTag={"dothello our company's works image"} />
            </div>
            <div className="feature__content">
              <SliderContainer title={config.slider} />
            </div>
          </div>
        </section>
      )}
    />
  );
};

export default OurWorks;