/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState } from "react";

const Form = () => {
  const [submissionStatus, setSubmissionStatus] = useState("incomplete");

  /**
   *
   * @param {*} e
   * @description handles submission of form on button click event
   */
  const handleSubmit = e => {
    // if newsletter
    if (submissionStatus !== "complete") {
      setSubmissionStatus("complete");
    }
  };

  /**
   * @description renders the html for the type of form
   * @param {*} type is form type to render
   */
  const renderFormFields = () => {
    return (
      <section className="form__section">

      <input
          type="email"
          placeholder="hello@email.com"
          name="EMAIL"
          className="form__field required email"
          id="mce-EMAIL"
        />

        <input
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
          className="btn-submit"
        />
      </section>
    );
  };

  /**
   * @description renders the submission success state of the form
   */
  const renderFormSuccess = () => {
    return (
      <section className="home__section">
        <h2>good job!</h2>
      </section>
    );
  };


  return (
    
    <form
      action="https://facebook.us12.list-manage.com/subscribe/post?u=86a11ea1d82d8d7d59e291be1&amp;id=e0c6771d09"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate form"
      target="_blank"
    >
      {submissionStatus === "complete"
        ? renderFormSuccess()
        : renderFormFields()}
    
    </form>
  );
};

export default Form;