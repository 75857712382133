import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight, faArrowRight, faAngleRight, faEnvelope} from "@fortawesome/free-solid-svg-icons";

const Button = props => {
  const { to, text, type } = props.config;

  const renderGhostBtn = () => {
    return (
      <button className={`${type}-btn`}>
       {text}
       <FontAwesomeIcon icon={faCaretRight} />
      </button>
    );
  };

  const renderPrimaryBtn = () => {
    return (
        <button className={`${type}-btn`}>{text}</button>
    );
  };

  const renderBtnPrime = () => (
    <a href={`${to}`} target={"blank" || false } className={`link-btn link-btn--${type}`}>
    <span className={`link-btn__icon link-btn__icon--${type}`}>
    <FontAwesomeIcon icon={faEnvelope} />
    </span>
    {text}

    </a>
  )
 

  // return (
  //   <Link className="btn-link" to={`#${to}`}>
  //     {type === 'secondary' ? renderGhostBtn() : renderBtnPrime()}
  //   </Link>
  // );

  return (
    renderBtnPrime(type, to)
  )
};

export default Button;
