import React from "react";
import Img from "gatsby-image";

const Step = (props) => {
  const { stepConfig, i } = props;

  React.useEffect(() => {
    const isMobile = window.innerWidth < 480;
    const stepCont = document.querySelector(".step__content");
  });

  const renderStepBullets = (bullets) =>
    bullets.map((bullet, key) => (
      <li key={key}>
        <div className="detail">
          <div className="detail__title-box">
            <h4>{bullet.title}</h4>
          </div>
        </div>
      </li>
    ));

  const renderStep = () => (
    <article className="step card--step timeline__item">
      <div className="step__img">
        <div className={`step-overlay overlay overlay--${stepConfig.title}`}>
          <Img
            className=" step-overlay__img-box"
            fluid={stepConfig.childImageSharp.fluid}
            alt={stepConfig.alt_tag}
          />
        </div>
        <div className="step__title-box">
          <span className="number-icon">{i + 1}</span>
          <h3>{stepConfig.title}</h3>
          <p>{stepConfig.sub_title}</p>
        </div>
      </div>

      <div className={`step__content `}>
        <div className="step__details">
          <ul>{renderStepBullets(stepConfig.bullets, stepConfig.title)}</ul>
        </div>
      </div>
    </article>
  );
  return renderStep();
};

export default Step;
