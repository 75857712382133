import React from "react";
import { StaticQuery, graphql } from "gatsby";
import FeatureImg from "./FeatureImg";
import FeatureHeadline from "./FeatureHeadline";
import AboutPersons from "./AboutPersons";

const About = () => {
  const config = {
    titleConfig: {
      metaTitle: "about us",
      title: "Why do we help?",
      subTitle: `
     
        Our mission is to help people help people. 
        We believe the best way to do that is by helping overwhelmed healthcare practitioners
        spend quality time with their current patients while gaining more patients.

        `,
    },
    content: "",
    tag: "about",
    button: {
      type: "ghost",
      text: "Contact Us",
      to: "#contact",
    },
  };
  return (
    <StaticQuery
      query={graphql`
        query {
          about: file(relativePath: { eq: "main/about.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <section id="about" className="home__section">
          <div className={`feature`}>
            <div className="feature__header">
              <FeatureHeadline config={config} />
              <FeatureImg
                image={data.about}
                altTag={"dothello about our company image"}
              />
            </div>
            <div className="feature__content">
              <AboutPersons />
            </div>
          </div>
        </section>
      )}
    />
  );
};

export default About;

// Journeying into the unknown is scary. The only thing to guide you is a conviction in your vision.
// As two entrepreneurs we relate. Frustrated no one could understand  our vision we decided to do it ourselves.
// Thus we left the security of a Fortune 500 company, and start-up to journey into the unknown
// and eventually surfacing with dotHello, a product design studio with the sole mission of empowering partners on
// communicating their values with a willing audience.

// Journeying into the unknown is scary. The only thing guiding you is the conviction in your vision. 
// As entrepreneurs, we relate. We created dotHello to empower partners by acting as an in-house design/development team on their journey.