import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Button from "./Button";
import Slider from "react-slick";
import Img from "gatsby-image";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  centerMode: true,
  variableWidth: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: false,
  autoplay: false,
  edgeFriction: true,
  draggable: true,
  rtl: true,
  pauseOnFocus: true,
};

const config = {
  sylvan: {
    title: `Sylvan LaCue`,
    desc: `SEO and Ecommerce for musician's nationwide tour.`,
    button: {
      type: "alt",
      text: "learn more",
      to: "",
    },
  },
  musicem: {
    title: `  Music'em App `,
    desc: `  Crafting a mobile Progressive Web App.    `,
    button: {
      type: "alt",
      text: "learn more",
      to: "",
    },
  },
  grace: {
    title: `  Grace Angel Care `,
    desc: `Developing a trustworthy brand and digital strategy.`,
    button: {
      type: "alt",
      text: "Let's Begin",
      to: "#contact",
    },
  },
  sokadusa: {
    title: `SOKADUSA`,
    desc: `Revamping a global non-profit's brand and digital presence.`,
    button: {
      type: "alt",
      text: "Let's Begin",
      to: "#contact",
    },
  },
  ukeys: {
    title: `UKeys`,
    desc: `Ecommerce and web design for a startup's patented piano products.`,
    button: {
      type: "alt",
      text: "Let's Begin",
      to: "#contact",
    },
  },
};
const SliderContainer = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          musicem: file(relativePath: { eq: "works/musicem-mockup.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 750) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          grace: file(relativePath: { eq: "works/grace.png" }) {
            childImageSharp {
              fluid(maxWidth: 750) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ukeys: file(relativePath: { eq: "works/sample-ukeys.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 750) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sokadusa: file(relativePath: { eq: "works/sokad-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 750) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sylvan: file(relativePath: { eq: "works/sample-sylvan.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 750) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <section className="slider-container">
          <h3 className="slider-container__title">{props.title.title}</h3>
          <Slider {...settings} className="slider-comp">
            <article className={`work`}>
              <div className="work__img-box">
                <Img
                  fluid={data.sokadusa.childImageSharp.fluid}
                  alt="web design, web development, and branding for nigerian non-profit sokadusa image"
                  className="work__img"
                />
              </div>
              <div className="work__body">
                <div className="work__tags"></div>
                <h3 className="work__title">{config.sokadusa.title}</h3>
                <p className="work__desc">{config.sokadusa.desc}</p>
                <Button config={config.sokadusa.button} />
              </div>
            </article>
      {/* <article className={`work`}>
              <div className="work__img-box">
                <Img
                  fluid={data.musicem.childImageSharp.fluid}
                  alt="design and development for musicem a mobile progressive web app image"
                  className="work__img"
                />
              </div>
              <div className="work__body">
                <div className="work__tags"></div>
                <h3 className="work__title">{config.musicem.title}</h3>
                <p className="work__desc">{config.musicem.desc}</p>
                <Button config={config.musicem.button} />
              </div>
            </article>
      */}
            <article className={`work`}>
            <div className="work__img-box work__img-box--grace">
              <Img
                fluid={data.grace.childImageSharp.fluid}
                alt="Developing a trustworthy brand and digital strategy for Grace Angel Care."
                className="work__img"
              />
            </div>
            <div className="work__body">
              <div className="work__tags"></div>
              <h3 className="work__title">{config.grace.title}</h3>
              <p className="work__desc">{config.grace.desc}</p>
              <Button config={config.grace.button} />
            </div>
          </article>
            <article className={`work`}>
              <div className="work__img-box">
                <Img
                  fluid={data.ukeys.childImageSharp.fluid}
                  alt="web design, development, and ecommerce for ukeys piano small business image"
                  className="work__img"
                />
              </div>
              <div className="work__body">
                <div className="work__tags"></div>
                <h3 className="work__title">{config.ukeys.title}</h3>
                <p className="work__desc">{config.ukeys.desc}</p>
                <Button config={config.ukeys.button} />
              </div>
            </article>
          </Slider>
        </section>
      )}
    />
  );
};

export default SliderContainer;
