/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import Img from "gatsby-image";

import { StaticQuery, graphql } from "gatsby";

const ServicesTabs = props => {
  const renderBulletPoints = (bullets, name) =>
    bullets.map((bullet, key) => (
      <li className={`li li--${name}`} key={key}>
        <FontAwesomeIcon icon={faCheck} />
        <h4 className="li__bullet">{bullet}</h4>
      </li>
    ));

  const renderTabs = () =>
    props.services.map(({ name, icon }, key) => (
      <Tab key={key}>
        <div className={`item item--${name}`}>
          <FontAwesomeIcon icon={icon} />
          <p className="item__name">{name}</p>
        </div>
      </Tab>
    ));

  return (
    <StaticQuery
      query={graphql`
        query {
          a: file(relativePath: { eq: "services/strategy/strategy-6.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 412) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          b: file(relativePath: { eq: "services/web/web.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 412) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Tabs>
          <TabList className="filter">{renderTabs()}</TabList>
          <TabPanel>
            <article className={`card card--service`}>
              <header className="card__header">
                <div className="service-feature-img">
                  <div className={`img-box img-box--${props.services[0].name}`}>
                    <Img fluid={data.a.childImageSharp.fluid} alt="opt image" />
                  </div>
                </div>
              </header>
              <div className="card__body">
                <div className="service-content">
                  <h3>{props.services[0].name_desc}</h3>
                  <div>
                    <p className="card__text">{props.services[0].detail}</p>
                  </div>
                  <div>
                    <ul className="service-content__list">
                      {renderBulletPoints(
                        props.services[0].detail_list,
                        props.services[0].name
                      )}
                    </ul>
                    {/* <div className="button-box hero__button-box">
                      <Button config={props.services[0].button} />
                    </div> */}
                  </div>
                </div>
              </div>
            </article>
          </TabPanel>
          <TabPanel>
            <article className={`card card--service`}>
              <header className="card__header">
                <div className="service-feature-img">
                  <div className={`img-box img-box--${props.services[1].name}`}>
                    <Img fluid={data.b.childImageSharp.fluid} alt="opt image" />
                  </div>
                </div>
              </header>
              <div className="card__body">
                <div className="service-content">
                  <h3>{props.services[1].name_desc}</h3>
                  <div>
                    <p className="card__text">{props.services[1].detail}</p>
                  </div>
                  <div>
                    <ul className="service-content__list">
                      {renderBulletPoints(
                        props.services[1].detail_list,
                        props.services[1].name
                      )}
                    </ul>
                
                  </div>
                </div>
              </div>
            </article>
          </TabPanel>
        
        </Tabs>
      )}
    />
  );
};

export default ServicesTabs;
