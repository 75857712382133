import React from "react";
import Img from "../images/our_approach_2.jpg";
import TitleBox from "./TitleBox";
import ContactForm from "./ContactForm";

const ContactUs = () => {
  const config = {
    titleConfig: {
      title: "Why wait?",
      subTitle:
        "Let's begin your journey to creating the best digital hello possible."
    },
    content: "slider",
    image: Img,
    tag: "process",
    button: {
      text: "Contact Us"
    }
  };
  return (
    <section id="contact" className="home__section home__section--contact">
      <article className="contact">
        <header className="contact__header">
          <TitleBox titleConfig={config.titleConfig} tag={config.tag} />
        </header>
        <div className="contact__content">
          <article className="form">
          <ContactForm />
          </article>
        </div>
      </article>
    </section>
  );
};
export default ContactUs;
