import React from "react";
import Navbar from "./Navbar";

const Header = () => (
  <header className="main-header">
    <Navbar />
  </header>
);

export default Header;
