/* eslint-disable no-undef */
import React, { useState } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { StaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";

const Navbar = () => {
  const links = [
    {
      title: "Contact Us",
      to: "contact",
    },
    {
      title: "Our Services",
      to: "services",
    },
    {
      title: "Our Works",
      to: "works",
    },
    {
      title: "About Us",
      to: "about",
    },
    {
      title: "Our Process",
      to: "process",
    },
  ];

  const [navbarOpen, setNavbarOpen] = useState(false);

  const renderLinks = () =>
    links.map(({ title, to }, key) => (
      <li className="nav__link" key={key}>
        <Link className="btn-link" to={`#${to}`}>
          {title}
        </Link>
      </li>
    ));
  const renderLinks2 = () =>
    links.map(({ title, to }, key) => (
      <Link className="btn-link" to={`#${to}`} key={key}>
        {title}
      </Link>
    ));

  const renderNavMenuMobile = () => {
    return (
      <div className="navbarMenuMobile">
        <ul className="navbarMenuMobile__ul">
          {links.map(({ title, to }, key) => (
            <Link
              onClick={() => setNavbarOpen(!navbarOpen)}
              className="btn-link"
              to={`#${to}`}
              key={key}
            >
              {title}
            </Link>
          ))}
        </ul>
      </div>
    );
  };

  /**
   * recognize 'window' after build
   */
  React.useEffect(() => {
    const threshHold = window.innerHeight * 0.1;
    window.addEventListener("scroll", () => {
      const navEl = document.querySelector(".nav");
      if (window.scrollY >= threshHold) {
        // add fix class
        navEl.classList.add("fixed-header");
      } else {
        // remove fix class
        navEl.classList.remove("fixed-header");
      }
    });
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "logos/logo-wg.png" }) {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <nav className="nav">
          <div className="desktop-nav">
            <div className="img-box nav__img-box">
              <a
                className="navbar-brand"
                href="#"
                name="brand"
                title="brand logo"
              >
                <Img
                  className="person-img"
                  alt={"navbar dothello logo"}
                  fluid={data.logo.childImageSharp.fluid}
                />
              </a>
            </div>
            <div className="nav__items">
              <ul className="nav__links">{renderLinks()}</ul>
            </div>
          </div>
          <div className="mobile-nav">
            <div className="img-box nav__img-box">
              <a
                className="navbar-brand"
                href="#"
                name="brand"
                title="brand logo"
              >
                <Img
                  className="person-img"
                  alt={"navbar dothello logo"}
                  fluid={data.logo.childImageSharp.fluid}
                />
              </a>
            </div>
            <FontAwesomeIcon
              onClick={() => setNavbarOpen(!navbarOpen)}
              className="navBarBtn"
              icon={faBars}
            />
            {navbarOpen ? renderNavMenuMobile() : ""}
          </div>
        </nav>
      )}
    />
  );
};

export default Navbar;
