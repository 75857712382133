/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faGithub,
  faDev,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";

const persons = [
  {
    title: "Patrick Tunga-Lergo",
    sub_title: "CEO, Co-Founder",
    details: "",
    social: {
      twitter: "https://twitter.com/patrickfunom",
      linkedin: "https://linkedin.com/in/patrickfunom",
      instagram: "https://instagram.com/patrickfunom",
      personal: "https://patricktungalergo.com",
      github: "https://github.com/ptlergo",
    },
  },
  {
    title: "Rosler Oriol",
    sub_title: "COO, Co-Founder",
    details: "",
    social: {
      twitter: "https://twitter.com/",
      linkedin: "https://linkedin.com/in/rosler-oriol-0a693124/",
      instagram: "https://instagram.com/absolutefiasco87",
      personal: "https://rosleroriol.com",
      github: "https://github.com/oriolr",
    },
  },
];
const config = {
  
  patButt: {
    type: "alt",
    text: "Say Hey To Pat",
    to: "mailto:patrick@dothello.io",
  },
  rosButt: {
    type: "alt",
    text: "Say Hi To Rosler",
    to: "mailto:rosler@dothello.io",
  },
};

const AboutPersons = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          rosler: file(relativePath: { eq: "rosler.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          pat: file(relativePath: { eq: "me5.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="about">
          <article className="person card card--person">
            {
              <header className="card__header">
                <div className="person__img-box">
                  <Img
                    className="person-img"
                    alt={"Patrick Tunga-Lergo"}
                    fluid={data.pat.childImageSharp.fluid}
                  />
                </div>
              </header>
            }

            <div className="card__content">
              <div className="title-box">
                <p className="title-box__title--sub">{persons[0].sub_title}</p>
                <h3 className="title-box__title">{persons[0].title}</h3>
              </div>
              <div className="card__details">
                {/* <p>{person.details}</p>*/}
              </div>
              <footer className="card__footer">
                <div>
                  <Button config={config.patButt} />
                </div>
              </footer>
            </div>
          </article>
          <article className="person card card--person">
            {
              <header className="card__header">
                <div className="person__img-box">
                  <Img
                    className="person-img"
                    alt={"Rosler Oriol"}
                    fluid={data.rosler.childImageSharp.fluid}
                  />
                </div>
              </header>
            }

            <div className="card__content">
              <div className="title-box">
              <p className="title-box__title--sub">{persons[1].sub_title}</p>
              <h3 className="title-box__title">{persons[1].title}</h3>
              </div>
              <div className="card__details">
                {/* <p>{person.details}</p>*/}
              </div>
              <footer className="card__footer">
                <div>
                  <Button config={config.rosButt} />
                </div>
              </footer>
            </div>
          </article>
        </div>
      )}
    />
  );
};

export default AboutPersons;
