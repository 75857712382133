import React from "react";

const TitleBox = (props) => {
  const { title, subTitle, metaTitle } = props.titleConfig;

  return (
    <div className="title-box">
      <p className="metaTitle">{metaTitle}</p>
      <h2 className="title-box__text">
        {/*<span className={`title-dot title-dot--${props.tag}`}>.</span>*/}
        {title}
      </h2>
      <h3 className="title-box__text title-box__text--sub">{subTitle}</h3>
    </div>
  );
};

export default TitleBox;
