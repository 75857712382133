/* eslint-disable no-unused-vars */
import React from "react";
import {
  faLightbulb,
  faHeart,
  faCode,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";

import Img from "../images/services/services-main.jpg";
import { StaticQuery, graphql } from "gatsby";

import ImgBranding from "../images/services/branding/branding-3.jpg";
import ImgStrategy from "../images/services/strategy/strategy-6.jpg";
import ImgWeb from "../images/services/web/web.jpg";
import ImgMobile from "../images/services/mobile/mobile-5.jpg";
import ServicesTabs from "./ServicesTabs";

import FeatureImg from "./FeatureImg";
import FeatureHeadline from "./FeatureHeadline";

const OurServices = () => {
  const config = {
    titleConfig: {
      metaTitle: "services",
      title: "How do we help?",
      subTitle: `
      We prescribe the ideal digital strategy for overwhelmed healthcare practitioners to save them more time to save more lives.
        `,
    },
    content: "slider",
    image: Img,
    tag: "services",
    button: {
      type: "primary",
      text: "Contact Us",
      to: "#contact",
    },

    servicesArr: [
      {
        name: "strategy",
        name_desc: "strategy & consulting",
        detail: `
        First impressions can only happen once. Before we commit to a pixel, a word, or even a line of code we consider your business goals and what's the most cost effective,
        time efficient, and elegant solutions to achieve them ensuring your company looks the part.
          `,
        detail_list: [
          "SEO",
          "Analytics",
          "Research",
          "Usability Testing",
          "performance optimization",
          "content writing",
        ],
        why_us_list: [
          "clarity of vision and purpose",
          "more qualified business opportunities",
          "increased customer acquisition",
        ],
        icon: faLightbulb,
        image: ImgStrategy,
        button: {
          type: "alt",
          text: "Let's Begin",
          to: "#contact",
        },
      },
      {
        name: "web app",
        name_desc: "web app design & development",
        detail: `
        We leverage web standards and usability best practices for a responsive and mobile-friendly web product. 
        Your product will be built with optimal conversion rates and be a great foundation to grow your business with an easy to manage admin panel.
         `,
        detail_list: [
          "ui/ux design",
          "prototyping",
          "Progressive Web App",
          "security and maintenance",
          "landing page",
          "company site",
        ],
        why_us_list: [
          "seamless integrations between key pieces of tech",
          "an easy to manage site",
          "stability and security",
        ],
        icon: faCode,
        image: ImgWeb,
        button: {
          type: "alt",
          text: "Let's Begin",
          to: "#contact",
        },
      },
     
    ],
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          services: file(relativePath: { eq: "services/services_main6.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <section id="services" className="home__section">
          <div className={`feature`}>
            <div className="feature__header">
              <FeatureHeadline config={config} />
              <FeatureImg
                image={data.services}
                altTag={"dothello our company's services image"}
              />
            </div>
            <div className="feature__content">
              <div className="services">
              <ServicesTabs services={config.servicesArr} />
              </div>
            </div>
          </div>
        </section>
      )}
    />
  );
};

export default OurServices;


// Think of us as your inhouse web team. 
// Our expertise and skillsets will help your practice get new clients, improve your reputation, and strengthen your existing relationships. 