import React from "react";
import TitleBox from "./TitleBox";
import Button from "./Button";

const FeatureHeadline = props => {
  return (
    <div className="feature__headline">
      <TitleBox titleConfig={props.config.titleConfig} tag={props.config.tag} />
      <div className="feature__actions">     
        <Button config={props.config.button} />
      </div>
    </div>
  );
};

export default FeatureHeadline;