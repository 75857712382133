/* eslint-disable no-unused-vars */
import React from "react";
import Hero from "../components/Hero";
import Layout from "../components/layout";
import OurWorks from "../components/OurWorks";
import ContactUs from "../components/ContactUs";
import OurApproach from "../components/OurApproach";
import OurServices from "../components/OurServices";
import About from "../components/About";
import Header from "../components/Header";
import Footer from "../components/Footer";

const HomePage = () => {
  return (
    <Layout>
      <Header />
      <Hero />
      <OurServices />
      <OurWorks />
      <About />
      <OurApproach />
      <ContactUs />
      <Footer />
    </Layout>
  );
};

export default HomePage;
