import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import HeroVid from "../images/calm-hand.mp4";
import HeroVidAlt from "../images/calm-hand.webm";
import Newsletter from "./Newsletter";
import Button from "./Button";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";

const config = {
  button: {
    type: "primary",
    text: "Start your free consultation",
    to: "#contact",
  },
};
const Hero = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          a: file(relativePath: { eq: "hero.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <section className="hero">
          <div className="vid-box">
            <video
              autoPlay
              loop
              muted
              playsInline
              poster={data.a.childImageSharp.fluid.src}
            >
              <source src={HeroVidAlt} type="video/webm" />
              <source src={HeroVid} type="video/mp4" />
              <track
                default
                kind="descriptions"
                label="dotHello Hero Banner Background Video"
              />
              <p> Sorry, your browser does not support embedded videos.</p>
            </video>
          </div>
          <div className="hero-wrapper">
            <div className="hero__title-box">
              <h1 className="hero__title">
                Say Hello To <br />
                The Digital World.
              </h1>
              <h1 className="hero__title hero__title--sub">
                dotHello prescribes the ideal digital strategy to overwhelmed
                healthcare practitioners providing them peace of mind.
              </h1>
              <div className="hero__button-box">
                <Button config={config.button} />
              </div>
            </div>
            <Newsletter />
            <span className="icon-box">
              <Link className="icon-box__link" to={`#works`}>
                <p>scroll down</p>
                <FontAwesomeIcon icon={faArrowCircleDown} />
              </Link>
            </span>
          </div>
        </section>
      )}
    />
  );
};

export default Hero;

// <Img
// className="video-img"
// alt={"dothello hero image"}
// fluid={data.a.childImageSharp.fluid}
// />