/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState } from "react";
import { faPhone, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ContactForm extends React.Component {
  state = {
    firstName: "",
    lastName: ""
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  postForm() {
    let url = "";
    fetch(url, {
      method: "post",
      body: data
    }).then(res => {
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    alert(`Welcome ${this.state.firstName} ${this.state.lastName}!`);
  };
  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          First name
          <input
            type="text"
            name="firstName"
            value={this.state.firstName}
            onChange={this.handleInputChange}
          />
        </label>
        <label>
          Last name
          <input
            type="text"
            name="lastName"
            value={this.state.lastName}
            onChange={this.handleInputChange}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    );
  }

  renderEmail() {
    return (
      <div className="contact-box">
        <a className="link" href="mailto:contact@dothello.io">
          <span>
            <FontAwesomeIcon icon={faEnvelopeOpen} />
            <h2 className="text">contact@dothello.io</h2>
          </span>
        </a>
        <a className="link" href="tel:407-680-1919">
          <span>
            <FontAwesomeIcon icon={faPhone} />
            <h3  className="text">+407-680-1919</h3>
          </span>
        </a>
      </div>
    );
  }

  render() {
    return this.renderEmail();
  }
}
