import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Button from "./Button";

import Img2 from "../images/approach/define.jpg";


import Step from "./Step";

const Timeline = () => {
  const config = {
    blurb: ` Our process results in success as they are the same steps we used to grow our own and our clients' companies. We'll do the same for you.`,
    button: {
      type: "primary",
      text: "Let's Begin",
      to: "#contact"
    },
    steps: [
      {
        title: "discover",
        alt_tag: "dothello web design company's approach step discover image",
        sub_title: ` 
        Our desire to discover what your organization's goals are is unrivaled. We see success, not as an accident, rather a product of passion; Your passion.
        `,
        bullets: [
          {
            img: Img2,
            title: "Understanding",
            sub_title: "understand your passion."
          },
          {
            img: Img2,
            title: "Recognition",
            sub_title: "Recognize target audience and project goals."
          },
          {
            img: Img2,
            title: "Reiterating",
            sub_title: "Reiterate shared vision."
          }
        ],
        button: {
          type: "secondary",
          text: "Let's Begin",
          to: "contact"
        }
      },
      {
        title: "define",
        alt_tag: "dothello web design company's approach step define image",
        sub_title: `
        We define how to increase the engagement of your audience.
        `,
        bullets: [
          {
            img: Img2,
            title: "project planning",
            sub_title: "craft project road map."
          },
          {
            img: Img2,
            title: "unique solution",
            sub_title: "SWOT analysis leads to unique solution."
          },
          {
            img: Img2,
            title: "cost",
            sub_title: "Competitive pricing."
          }
        ],
        button: {
          type: "secondary",
          text: "Let's Begin",
          to: "contact"
        }
      },
      {
        title: "design",
        alt_tag: "dothello web design company's approach step design image",
        sub_title: `
        We apply over 7 years of UX and UI design experience to craft intuitive visual representations of the defined solution.
        

        `,
        bullets: [
          {
            img: Img2,
            title: "Information Architecture",
            sub_title: "create sitemap, user personas and journeys."
          },
          {
            img: Img2,
            title: "visual and ux design",
            sub_title:
              "branded wireframes, and prototypes for initial feedback."
          },
          {
            img: Img2,
            title: "usability and usefulness testing",
            sub_title:
              "intuitive adaptive design guiding users to desired actions."
          }
        ],
        button: {
          type: "secondary",
          text: "Let's Begin",
          to: "contact"
        }
      },
      {
        title: "develop",
        alt_tag: "dothello web design company's approach step develop image",
        sub_title: `
        We build a proven product to work across all desired digital platforms with no mistakes and room for growth.
        `,
        bullets: [
          {
            img: Img2,
            title: "Cross Platform Compatibility",
            sub_title: "Maximize use of product."
          },
          {
            img: Img2,
            title: "3rd Party Integration",
            sub_title: "integrate the right tech for optimal performance."
          },
          {
            img: Img2,
            title: "QA Testing",
            sub_title: "validation for W3C standards and pass all use cases."
          }
        ],
        button: {
          type: "secondary",
          text: "Let's Begin",
          to: "contact"
        }
      },
      {
        title: "deliver",
        alt_tag: "dothello web design company's approach step deliver image",
        sub_title: `
        Think of us as your in-house web team. We're all in on your product's success. Our open availability will ensure a fast launch and long-lasting quality.
        `,
        bullets: [
          {
            img: Img2,
            title: "Refinement",
            sub_title: "informed final changes."
          },
          {
            img: Img2,
            title: "training",
            sub_title: "self sufficiency."
          },
          {
            img: Img2,
            title: "Deployment",
            sub_title: "deploy to app stores, and/or servers."
          },
          {
            img: Img2,
            title: "support and maintenance",
            sub_title: "troubleshoot, and security updates."
          }
        ],
        button: {
          type: "secondary",
          text: "Let's Begin",
          to: "contact"
        }
      }
    ]
  };

  const renderSteps = images => {
    // new array with queried images
    const stepConfigs = [
      { ...config.steps[0], ...images.discover },
      { ...config.steps[1], ...images.define },
      { ...config.steps[2], ...images.design },
      { ...config.steps[3], ...images.develop },
      { ...config.steps[4], ...images.deliver }
    ];

    // render each step template based on the config prop
    return stepConfigs.map((stepConfig, key) => (
      <Step stepConfig={stepConfig} i={key} key={key} />
    ));
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          discover: file(relativePath: { eq: "approach/new/discover.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          define: file(relativePath: { eq: "approach/new/define.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          design: file(relativePath: { eq: "approach/new/design.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          develop: file(relativePath: { eq: "approach/new/develop.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          deliver: file(relativePath: { eq: "approach/new/deliver.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <div className="timeline">
          {renderSteps(data)}
          <article className="step step--last">
            <div className="step__content">
              <h3 className="title-box__text--sub">
               {config.blurb}
              </h3>
              <footer>
                <Button config={config.button} />
              </footer>
            </div>
          </article>
        </div>
      )}
    />
  );
};

export default Timeline;
