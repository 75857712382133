import React from "react";
import Img from "../images/whatwedo.jpg";
import Form from "./Form";

const Newsletter = () => {
  const config = {
    titleConfig: {
      title: "Contact Us",
      subTitle:
        "At dotHello we design the perfect introduction for you to clients.",
        newsletter_title: "Get The Latest News And Updates"

    },
    content: "slider",
    image: Img,
    tag: "works",
    button: {
      text: "Contact Us"
    },
    form: {
      type: "newsletter"
    }
  };

  return (
    <section className="newsletter">
      <header className="newsletter__header">
        <p className="newsletter__text">{config.titleConfig.newsletter_title}</p>
      </header>
        <Form type={config.form.type} />
    </section>
  );
};

export default Newsletter;