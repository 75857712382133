import React from "react";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const date = () => {
  return new Date().getFullYear();
};

const social = () => (
  <div className="socialicons">
    <a
      href="https://twitter.com/dothello_"
      target="_blank"
      rel="noopener noreferrer"
      name="social link"
      title="social link"
    >
      <FontAwesomeIcon icon={faTwitter} />
    </a>
    <a
      href="https://www.facebook.com/dothelloworld"
      target="_blank"
      rel="noopener noreferrer"
      name="social link"
      title="social link"
    >
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a
      href="https://www.linkedin.com/company/dothello/?viewAsMember=true"
      target="_blank"
      rel="noopener noreferrer"
      name="social link"
      title="social link"
    >
      <FontAwesomeIcon icon={faLinkedinIn} />
    </a>
    <br />
  </div>
);

const socialFooter = () => (
  <div className="socialfooter">
    <h4 className="footer__title title-box__text title-box__text">
      connect with us
    </h4>
    {social()}
  </div>
);

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          a: file(relativePath: { eq: "logos/logo-w.png" }) {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <footer className="site-footer">
          <div className="footer-logo ">
            <div className="img-box">
              <Img
                fluid={data.a.childImageSharp.fluid}
                className="logo-img"
                alt="dothello orlando florida web design and development company logo"
              />
            </div>
            <div>
              <p>&#169;&nbsp;{date()}&nbsp;dotHello LLC</p>
            </div>
          </div>
          <div className="footer-contact">
            <h4 className="footer__title title-box__text title-box__text">
              say hello
            </h4>
            <div>
              <p className="footer__title--sub title-box__text title-box__text">
                +407-680-1919
              </p>
              <p className="footer__title--sub title-box__text title-box__text">
                contact@dothello.io
              </p>
            </div>
          </div>
        </footer>
      )}
    />
  );
};

export default Footer;
