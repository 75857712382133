import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Img from "../images/our_approach_2.jpg";
import FeatureImg from "./FeatureImg";
import FeatureHeadline from "./FeatureHeadline";
import Timeline from "./Timeline";

const OurApproach = () => {
  const config = {
    titleConfig: {
      metaTitle:"process",
      title: "How do we guide you to success?",
      subTitle:
      `How did you find success with your current clients? Helping you get new clients is easy. 
      Our approach is to identify your goals and bring forth an ideal strategy to attain long-term success.`
    },
    content: "slider",
    image: Img,
    tag: "process",
    button: {
      type: "ghost",
      text: "Contact Us",
      to: "#contact"
    }
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          approach: file(relativePath: { eq: "main/works_main.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <section id="process" className="home__section">
          <div className={`feature`}>
            <div className="feature__header">
              <FeatureHeadline config={config} />
              <FeatureImg image={data.approach} altTag={"dothello our company's process image"}/>
            </div>
            <div className="feature__content">
              <Timeline />
            </div>
          </div>
        </section>
      )}
    />
  );
};

export default OurApproach;
