import React from "react";
import Img from "gatsby-image";

const FeatureImg = (props) => {
  return (
    <div className="test">
      <Img
        className="test__img-box"
        alt={props.altTag || "dothello section image"}
        fluid={props.image.childImageSharp.fluid}
      />
    </div>
  );
};

export default FeatureImg;
